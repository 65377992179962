/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Bank } from './Bank';
import {
    BankFromJSON,
    BankFromJSONTyped,
    BankToJSON,
} from './Bank';
import type { HostColors } from './HostColors';
import {
    HostColorsFromJSON,
    HostColorsFromJSONTyped,
    HostColorsToJSON,
} from './HostColors';
import type { Image } from './Image';
import {
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
} from './Image';

/**
 * 
 * @export
 * @interface Host
 */
export interface Host {
    /**
     * 
     * @type {Image}
     * @memberof Host
     */
    image: Image;
    /**
     * 
     * @type {number}
     * @memberof Host
     */
    commissionPercentage?: number;
    /**
     * 
     * @type {string}
     * @memberof Host
     */
    hotelName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Host
     */
    toursIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Host
     */
    hideSearch?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Host
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof Host
     */
    phone?: string;
    /**
     * 
     * @type {HostColors}
     * @memberof Host
     */
    hostColors?: HostColors;
    /**
     * 
     * @type {Bank}
     * @memberof Host
     */
    bank?: Bank;
}

/**
 * Check if a given object implements the Host interface.
 */
export function instanceOfHost(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "hotelName" in value;
    isInstance = isInstance && "toursIds" in value;

    return isInstance;
}

export function HostFromJSON(json: any): Host {
    return HostFromJSONTyped(json, false);
}

export function HostFromJSONTyped(json: any, ignoreDiscriminator: boolean): Host {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'image': ImageFromJSON(json['image']),
        'commissionPercentage': !exists(json, 'commissionPercentage') ? undefined : json['commissionPercentage'],
        'hotelName': json['hotelName'],
        'toursIds': json['toursIds'],
        'hideSearch': !exists(json, 'hideSearch') ? undefined : json['hideSearch'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'hostColors': !exists(json, 'hostColors') ? undefined : HostColorsFromJSON(json['hostColors']),
        'bank': !exists(json, 'bank') ? undefined : BankFromJSON(json['bank']),
    };
}

export function HostToJSON(value?: Host | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image': ImageToJSON(value.image),
        'commissionPercentage': value.commissionPercentage,
        'hotelName': value.hotelName,
        'toursIds': value.toursIds,
        'hideSearch': value.hideSearch,
        'address': value.address,
        'phone': value.phone,
        'hostColors': HostColorsToJSON(value.hostColors),
        'bank': BankToJSON(value.bank),
    };
}

