import React, { memo } from "react";
import { useLazyComponent } from "../../../hooks";

export interface PublicMenuProps {
  translate: Translate;
  isActiveMenu: boolean;
  onSetIsActiveMenu: () => void;
  currentLanguageCode: LanguageCode;
  onChangeLanguage: (languageCode: LanguageCode) => void;
}

interface LazyPublicMenuProps extends PublicMenuProps {
  theme: Theme;
  subdomain: Subdomain;
}

const PublicMenu = ({
  theme,
  subdomain,
  ...props
}: LazyPublicMenuProps): React.ReactElement => {
  const LazyComponent = useLazyComponent<PublicMenuProps>(
    () => import(`./${theme}/${subdomain}/Public.Menu`),
    "empty",
    [theme, subdomain]
  );

  return <LazyComponent {...props} />;
};

export default memo(PublicMenu);
