import React, { memo } from "react";
import { useLazyComponent } from "../../../hooks";

export interface PublicHeaderProps {
  translate: Translate;
  currentLanguageCode: LanguageCode;
  onChangeLanguage: (languageCode: LanguageCode) => void;
  onSetIsActiveMenu: () => void;
}

interface LazyComponentProps {
  theme: Theme;
  subdomain: Subdomain;
}

const PublicHeader = ({
  theme,
  subdomain,
  ...props
}: PublicHeaderProps & LazyComponentProps): React.ReactElement => {
  const LazyComponent = useLazyComponent<PublicHeaderProps>(
    () => import(`./${theme}/${subdomain}/Public.Header`),
    "empty",
    [theme, subdomain]
  );

  return <LazyComponent {...props} />;
};

export default memo(PublicHeader);
