import React, { useState } from "react";
import styled from "styled-components";
import { Layout } from "antd";
import { useLanguage, useThemeProvider } from "../../../providers";
import { useI18n } from "../../../hooks";
import PublicHeader from "./Public.Header";
import PublicMenu from "./Public.Menu";
import PublicFooter from "./Public.Footer";

interface Props {
  children: JSX.Element;
}

export const PublicLayout = ({ children }: Props): JSX.Element => {
  const translate = useI18n();
  const { onChangeLanguage, currentLanguageCode } = useLanguage();
  const { theme, subdomain } = useThemeProvider();

  const [isActiveMenu, setIsActiveMenu] = useState<boolean>(false);

  const onSetIsActiveMenu = () => setIsActiveMenu(!isActiveMenu);

  return (
    <LayoutStyled>
      <PublicHeader
        theme={theme}
        subdomain={subdomain}
        translate={translate}
        currentLanguageCode={currentLanguageCode}
        onChangeLanguage={onChangeLanguage}
        onSetIsActiveMenu={onSetIsActiveMenu}
      />
      <LayoutBodyStyled>{children}</LayoutBodyStyled>
      <PublicMenu
        theme={theme}
        subdomain={subdomain}
        translate={translate}
        isActiveMenu={isActiveMenu}
        currentLanguageCode={currentLanguageCode}
        onChangeLanguage={onChangeLanguage}
        onSetIsActiveMenu={onSetIsActiveMenu}
      />
      <PublicFooter
        theme={theme}
        subdomain={subdomain}
        translate={translate}
        currentLanguageCode={currentLanguageCode}
      />
    </LayoutStyled>
  );
};

const LayoutStyled = styled(Layout)`
  min-height: 100vh;
  height: auto;
  background-color: ${({ theme }) => theme.colors.white};

  .ant-layout-header {
    padding: 0;
    box-sizing: border-box;
    line-height: 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const LayoutBodyStyled = styled(Layout.Content)`
  padding: 0;
  overflow: initial;
  background-color: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
`;
