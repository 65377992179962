import React, { memo } from "react";
import { useLazyComponent } from "../../../hooks";

export interface PublicFooterProps {
  translate: Translate;
  currentLanguageCode: LanguageCode;
}

interface LazyComponentProps {
  theme: Theme;
  subdomain: Subdomain;
}

const PublicFooter = ({
  theme,
  subdomain,
  ...props
}: PublicFooterProps & LazyComponentProps): React.ReactElement => {
  const LazyComponent = useLazyComponent<PublicFooterProps>(
    () => import(`./${theme}/${subdomain}/Public.Footer`),
    () => import(`./DefaultPublicFooter`),
    [theme, subdomain]
  );

  return <LazyComponent {...props} />;
};

export default memo(PublicFooter);
